import React from "react";
import price from "../tools/price";
import { useKits } from "../services/useKits";
import sumKits from "../tools/sumKits";
import {
  Tile,
  Button,
  DataTableSkeleton,
  DataTable,
  StructuredListBody,
  StructuredListCell,
  StructuredListWrapper,
  StructuredListHead,
  StructuredListRow,
} from "carbon-components-react";
import { Add16, Save16 as Save } from "@carbon/icons-react";

const {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeader,
  TableCell,
  TableContainer,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TableExpandRow,
  TableExpandedRow,
  TableSelectRow,
  TableBatchAction,
  TableBatchActions,
  TableExpandHeader,
  TableSelectAll,
} = DataTable;

const KitsPage = () => {
  return (
    <>
      <Tile style={{ maxWidth: "256px", marginBottom: "16px" }}>
        Total
        <h1 style={{ textAlign: "right" }}>{price(0)}</h1>
      </Tile>
      <KitsTable />
    </>
  );
};

const KitsTable = () => {
  const kits = useKits();
  const headers = [
    { key: "name", header: "Kit" },
    { key: "fakeTotal", header: "Price" },
  ];
  return (
    <DataTable
      rows={kits}
      headers={headers}
      isSortable
      render={({
        rows,
        headers,
        getHeaderProps,
        getSelectionProps,
        getToolbarProps,
        getBatchActionProps,
        getRowProps,
        onInputChange,
        selectedRows,
        getTableProps,
        getTableContainerProps,
      }) => (
        <TableContainer
          title="Kits"
          description="Here you can add, edit and delete kits. Select kits with the checkbox to start a configuration."
          {...getTableContainerProps()}
        >
          <TableToolbar {...getToolbarProps()}>
            <TableBatchActions {...getBatchActionProps()}>
              <TableBatchAction
                renderIcon={Save}
                iconDescription="Save the selected rows"
                onClick={() => sumKits(selectedRows)}
              >
                Save
              </TableBatchAction>
            </TableBatchActions>
            <TableToolbarContent>
              <TableToolbarSearch onChange={onInputChange} />
              <Button
                renderIcon={Add16}
                iconDescription="Add a new kit"
                hasIconOnly
                onClick={() => alert("Add a new kit")}
              />
            </TableToolbarContent>
          </TableToolbar>
          {rows.length !== 0 ? (
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  <TableExpandHeader />
                  <TableSelectAll {...getSelectionProps()} />
                  {headers.map((header, i) => (
                    <TableHeader key={i} {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <React.Fragment key={row.id}>
                    <TableExpandRow {...getRowProps({ row })}>
                      <TableSelectRow {...getSelectionProps({ row })} />
                      {row.cells.map((cell) => (
                        <TableCell key={cell.id}>
                          {cell.id.includes("fakeTotal")
                            ? price(cell.value)
                            : cell.value}
                        </TableCell>
                      ))}
                    </TableExpandRow>
                    <TableExpandedRow colSpan={headers.length + 2}>
                      <StructuredListWrapper>
                        <StructuredListHead>
                          <StructuredListRow head>
                            <StructuredListCell head>Code</StructuredListCell>
                            <StructuredListCell head>
                              Quantity
                            </StructuredListCell>
                            <StructuredListCell head>
                              Subotal
                            </StructuredListCell>
                          </StructuredListRow>
                        </StructuredListHead>
                        <StructuredListBody>
                          <StructuredListRow>
                            <StructuredListCell noWrap>
                              DX5550123
                            </StructuredListCell>
                            <StructuredListCell>1</StructuredListCell>
                            <StructuredListCell>50,00 €</StructuredListCell>
                          </StructuredListRow>
                        </StructuredListBody>
                      </StructuredListWrapper>
                    </TableExpandedRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          ) : (
            <DataTableSkeleton
              columnCount={headers.length}
              rowCount={10}
              headers={headers}
              showHeader={false}
              showToolbar={false}
            />
          )}
        </TableContainer>
      )}
    />
  );
};

export default KitsPage;
