import { db } from "../services/firebase";

export default async function sumKits(kits) {
  var sum = 0;
  //   const kitIds = kits.forEach((kit) => {
  //     return kit.id;
  //   });
  db
    //   .collection("kits")
    .collectionGroup("kitProducts")
    .get()
    .then((response) => {
      const fetchedProducts = [];
      response.forEach((document) => {
        const fetchedProduct = {
          id: document.id,
          ...document.data(),
        };
        fetchedProducts.push(fetchedProduct);
      });
      console.log(fetchedProducts);
    });
  //   products.forEach((product) => {
  //     console.log("Found products with code: ", product.code);
  //   });
  return sum;
}
