import React from "react";
import { db } from "./firebase";

export const useProducts = () => {
  const [products, setProducts] = React.useState([]);
  React.useEffect(() => {
    const unsubscribe = db
      .collection("products")
      .onSnapshot(snapshot => {
        const listItems = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }))
        setProducts(listItems)
      })
    return () => unsubscribe()
  }, [])
  return products
}