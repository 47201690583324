import React, { useContext } from "react";
import { Route } from "react-router-dom";
import Forbidden from "../content/Forbidden";
import { AuthContext } from "./firebase";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = useContext(AuthContext);
  const page = auth.user ? Component : Forbidden;
  return <Route {...rest} component={page} />;
};
