import React from "react";
import { auth } from "../services/firebase";

const Dashboard = () => {
  return (
    <>
      <h1 style={{ textAlign: "center" }}>
        Hello, {auth.currentUser.displayName.replace(/\s.*/, "")}!
      </h1>
    </>
  );
};

export default Dashboard;
