import React from "react";
import price from "../tools/price";
import { useProducts } from "../services/useProducts";
import { Button, DataTableSkeleton, DataTable } from "carbon-components-react";
import { Add16 } from "@carbon/icons-react";

const {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeader,
  TableCell,
  TableContainer,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
} = DataTable;

const ProductsPage = () => {
  const products = useProducts();
  const headers = [
    { key: "code", header: "Code" },
    { key: "description", header: "Description" },
    { key: "price", header: "Price" },
  ];
  return (
    <DataTable rows={products} headers={headers} isSortable>
      {({ rows, headers, onInputChange, getHeaderProps, getTableProps }) => (
        <TableContainer
          title="Products"
          description="Here you can add, edit and delete products."
        >
          <TableToolbar>
            <TableToolbarContent>
              <TableToolbarSearch defaultExpanded onChange={onInputChange} />
              <Button
                renderIcon={Add16}
                iconDescription="Add a new product"
                hasIconOnly
                onClick={() => alert("Add a new product")}
              />
            </TableToolbarContent>
          </TableToolbar>
          {rows.length !== 0 ? (
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader
                      key={header.key}
                      {...getHeaderProps({ header })}
                    >
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>
                        {cell.id.includes("price")
                          ? price(cell.value)
                          : cell.value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <DataTableSkeleton
              columnCount={headers.length}
              rowCount={10}
              headers={headers}
              showHeader={false}
              showToolbar={false}
            />
          )}
        </TableContainer>
      )}
    </DataTable>
  );
};

export default ProductsPage;
