import React, { useState, useContext } from "react";
import { Button, InlineLoading } from "carbon-components-react";
import { AuthContext, logIn } from "../services/firebase";
import { Error32, Login16 } from "@carbon/icons-react";

function Login() {
  const [status, setStatus] = useState(null);
  if (status == null)
    return (
      <Button
        renderIcon={Login16}
        style={{ justifyContent: "center" }}
        onClick={() => {
          setStatus("active");
          logIn(setStatus);
        }}
      >
        Login
      </Button>
    );
  if (status === "active")
    return (
      <InlineLoading
        description={"Logging in..."}
        status={status}
        style={{ justifyContent: "center" }}
      />
    );
  if (status === "error")
    return (
      <>
        <InlineLoading
          description={"Authentication error"}
          status={status}
          style={{ justifyContent: "center", marginBottom: "8px" }}
        />
        <Button kind="tertiary" size="sm" onClick={() => setStatus(null)}>
          Try again
        </Button>
      </>
    );
}

const Forbidden = () => {
  console.log(useContext(AuthContext));
  return (
    <div style={{ textAlign: "center", marginTop: "128px" }}>
      <Error32 />
      <h1 style={{ marginTop: "16px", marginBottom: "16px" }}>Keep out!</h1>
      <p style={{ marginBottom: "64px" }}>
        To visit this page you need to login.
      </p>
      <Login />
    </div>
  );
};

export default Forbidden;
