import { Route, Switch } from "react-router-dom";
import { AuthProvider } from "./services/firebase";
import { PrivateRoute } from "./services/PrivateRoute";
import { Content } from "carbon-components-react";
import Nav from "./components/Nav";
import Welcome from "./content/Welcome";
import Products from "./content/Products";
import Kits from "./content/Kits";
import Dashboard from "./content/Dashboard";
import Profile from "./content/Profile";
import FourOhFour from "./content/FourOhFour";

function App() {
  return (
    <AuthProvider>
      <Nav />
      <Content>
        <Switch>
          <Route exact path="/" component={Welcome} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/profile" component={Profile} />
          <PrivateRoute path="/products" component={Products} />
          <PrivateRoute path="/kits" component={Kits} />
          <Route path="*" component={FourOhFour} />
        </Switch>
      </Content>
    </AuthProvider>
  );
}

export default App;
