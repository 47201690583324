import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyBKl-ukr8X79KeT7FWJgO7onJJ7Y7ZSaJs",
  authDomain: "dx-react.firebaseapp.com",
  projectId: "dx-react",
  storageBucket: "dx-react.appspot.com",
  messagingSenderId: "402226812720",
  appId: "1:402226812720:web:7c3fee5da8197016581af5",
  measurementId: "G-R1ZEYRZWZX",
};

firebase.initializeApp(config);

export const auth = firebase.auth();
export const db = firebase.firestore();

const provider = new firebase.auth.GoogleAuthProvider();
export const logIn = (setStatus) => {
  auth
    .signInWithPopup(provider)
    .then(() => {
      <Redirect to="/dashboard" exact />;
    })
    .catch(() => {
      setStatus("error");
    });
};

export const logOut = () => {
  auth
    .signOut()
    .then(() => {
      <Redirect to="/" exact />;
    })
    .catch((error) => {
      console.log(error.message);
    });
};

export const AuthContext = React.createContext();
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged(setUser);
  }, []);

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
};
