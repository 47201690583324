import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  Header,
  HeaderContainer,
  HeaderName,
  HeaderNavigation,
  HeaderMenuButton,
  HeaderMenuItem,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent,
  SideNav,
  SideNavItems,
  HeaderSideNavItems,
} from "carbon-components-react";
import { Login20, Logout20, UserAvatar20 } from "@carbon/icons-react";
import { auth, AuthContext, logIn, logOut } from "../services/firebase";

const Nav = () => {
  const { user } = useContext(AuthContext);
  const pages = [
    {
      name: "Kits",
      path: "/kits",
    },
    {
      name: "Products",
      path: "/products",
    },
  ];
  return (
    <HeaderContainer
      render={({ isSideNavExpanded, onClickSideNavExpand }) => (
        <Header aria-label="DX React">
          <SkipToContent />
          {user ? (
            <HeaderMenuButton
              aria-label="Open menu"
              onClick={onClickSideNavExpand}
              isActive={isSideNavExpanded}
            />
          ) : null}
          <HeaderName element={Link} to="/" prefix="DX">
            React
          </HeaderName>
          {user ? (
            <>
              <HeaderNavigation aria-label="Carbon Tutorial">
                {pages.map((page, index) => {
                  return (
                    <HeaderMenuItem element={Link} to={page.path} key={index}>
                      {page.name}
                    </HeaderMenuItem>
                  );
                })}
              </HeaderNavigation>
              <SideNav
                aria-label="Side navigation"
                expanded={isSideNavExpanded}
                isPersistent={false}
              >
                <SideNavItems>
                  <HeaderSideNavItems>
                    {pages.map((page, index) => {
                      return (
                        <HeaderMenuItem
                          element={Link}
                          to={page.path}
                          key={index}
                        >
                          {page.name}
                        </HeaderMenuItem>
                      );
                    })}
                  </HeaderSideNavItems>
                </SideNavItems>
              </SideNav>
            </>
          ) : null}
          <HeaderGlobalBar>
            {user ? (
              <>
                <Link to="/profile">
                  <HeaderGlobalAction aria-label="Profile">
                    {auth.currentUser.photoURL ? (
                      <img
                        src={auth.currentUser.photoURL}
                        alt="Your avatar"
                        width="30px"
                        style={{ borderRadius: "50%" }}
                      />
                    ) : (
                      <UserAvatar20 />
                    )}
                  </HeaderGlobalAction>
                </Link>
                <HeaderGlobalAction
                  aria-label="Logout"
                  onClick={() => logOut()}
                >
                  <Logout20 />
                </HeaderGlobalAction>
              </>
            ) : (
              <HeaderGlobalAction aria-label="Login" onClick={() => logIn()}>
                <Login20 />
              </HeaderGlobalAction>
            )}
          </HeaderGlobalBar>
        </Header>
      )}
    />
  );
};

export default Nav;
