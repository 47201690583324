import React from "react";
import { auth, logOut } from "../services/firebase";
import { Button } from "carbon-components-react";

const Profile = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        minHeigh: "48vh",
        alignItems: "center",
      }}
    >
      <div
        style={{
          maxWidth: "300px",
          marginRight: "32px",
        }}
      >
        <img
          src={auth.currentUser.photoURL}
          alt="Your avatar"
          width="96px"
          height="96px"
          style={{
            // borderRadius: "50%",
            display: "block",
            marginBottom: "24px",
          }}
        />
        <small style={{ color: "gray", marginBottom: "4px" }}>Your name</small>
        <p style={{ marginBottom: "16px" }}>{auth.currentUser.displayName}</p>
        <small style={{ color: "gray", marginBottom: "4px" }}>Your email</small>
        <p style={{ marginBottom: "16px" }}>{auth.currentUser.email}</p>
        <Button kind="ghost" onClick={() => logOut()}>
          Logout
        </Button>
      </div>
    </div>
  );
};

export default Profile;
