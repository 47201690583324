import React from "react";
import { db } from "./firebase";

export const useKits = () => {
  const [kits, setKits] = React.useState([]);
  React.useEffect(() => {
    const unsubscribe = db.collection("kits").onSnapshot((snapshot) => {
      const listItems = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setKits(listItems);
    });
    return () => unsubscribe();
  }, []);
  return kits;
};
