import React from "react";
import { Button } from "carbon-components-react";
import { MisuseOutline32, Home16 } from "@carbon/icons-react";
import { Link } from "react-router-dom";

const FourOhFour = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "128px" }}>
      <MisuseOutline32 />
      <h1 style={{ marginTop: "16px", marginBottom: "16px" }}>Ooops!</h1>
      <p style={{ marginBottom: "64px" }}>Looks like you are lost.</p>
      <Link to="/">
        <Button renderIcon={Home16} style={{ justifyContent: "center" }}>
          Take me home
        </Button>
      </Link>
    </div>
  );
};

export default FourOhFour;
